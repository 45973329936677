import React from "react"

export const StarBanner = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M28.8105 0.553467C35.3001 18.5671 31.1153 16.4702 48.4962 8.72422C40.3254 26.1051 38.8612 21.7034 56.6669 28.6178C38.6533 35.1074 40.7502 30.9226 48.4962 48.3036C31.1153 40.1328 35.5079 38.6686 28.8105 56.4743C22.113 38.4607 26.2978 40.5576 8.91684 48.3036C17.0786 30.9226 18.5428 35.3153 0.746094 28.6178C18.7597 21.9203 16.6628 26.1051 8.91684 8.72422C26.2978 16.8859 21.896 18.3502 28.8105 0.553467Z"
      fill="#E8BC00"
    />
  </svg>
)
