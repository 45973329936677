import React from "react"

import "./styles.css"
import { Instagram } from "../svgs/instagram"
import { StaticImage } from "gatsby-plugin-image"

const InstaFeeds = () => {
  return (
    <div className="insta-container">
      <div className="feeds">
        <StaticImage
          className="feedContainer"
          src={`../../images/insta/1.webp`}
          alt={`instagram image 1`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/2.webp`}
          alt={`instagram image 2`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/3.webp`}
          alt={`instagram image 3`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/4.webp`}
          alt={`instagram image 4`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/6.webp`}
          alt={`instagram image 6`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/7.webp`}
          alt={`instagram image 7`}
        />

        <StaticImage
          className="feedContainer"
          src={`../../images/insta/10.webp`}
          alt={`instagram image 10`}
        />
      </div>
      <div className="tag">
        <Instagram />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/iki.brussels/"
        >
          <h1>IKI.brussels</h1>
        </a>
      </div>
    </div>
  )
}

export default InstaFeeds
