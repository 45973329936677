import React, { useEffect } from "react"

import { useTranslation } from "gatsby-plugin-react-i18next"
import { BannerMenu } from "../components/bannerMenu"
import { TasteMe } from "../components/svgs/tasteMe"
import { Onion } from "../components/svgs/onion"

import "./styles.css"
import "../index.css"
import { IkiBackground } from "../components/svgs/ikiBackground"
import { BannerFollow } from "../components/bannerFollow"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { ArrowOrder } from "../components/svgs/arrowOrder"
import InstaFeeds from "../components/instaFeeds"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import typeform from "../config/typeform"

export default function Home({ location }) {
  const { t } = useTranslation("home")

  useEffect(() => typeform())

  return (
    <div className="home">
      <Seo />
      <div className="hero">
        <Header pathname={location.pathname} />
        <div className="bigImage">
          <StaticImage
            className="bowl"
            src="../images/bowl-meal.webp"
            placeholder="none"
            objectPosition="left bottom"
            alt="bowl"
          />
          <TasteMe className="sticker" />
        </div>
        <StaticImage
          className="arrow"
          src="../images/arrow-hero.svg"
          alt="arrow"
          placeholder="tracedSVG"
        />
        <div className="introduction">
          <img className="logo" src="/iki.svg" alt="logo" />
          <h4 className="bold">
            {t(
              "À la croisée des chemins entre la Belgique et le Vietnam, nos plats aux saveurs gourmandes sont préparés avec amour à partir d’ingrédients variés de qualité."
            )}
          </h4>
          <button
            data-tf-popup="VdEkYv9y"
            data-tf-iframe-props="title=Iki orders"
            data-tf-medium="snippet"
          >
            {t("Passer commande")}
          </button>
        </div>
      </div>
      <BannerMenu />
      <div className="presentation">
        <div className="presentation-image">
          <div className="ikiBackground">
            <IkiBackground />
          </div>
          <StaticImage className="photo" src="../images/bar.webp" alt="bar" />
        </div>
        <div className="presentation-text">
          <div className="centeredBlock">
            <h2>{t("Qui sommes-nous ?")}</h2>
            <h4 className="bold">
              {t(
                "Situé en plein coeur d’Ixelles, dans un quartier animé et branché de la capitale européenne, IKI vous invite à découvrir une cuisine eurasienne saine et raffinée !"
              )}
            </h4>
            <div>
              <h4 className="uppercase bold">
                {t("Limiter notre impact écologique")}
              </h4>
              <small>
                {t(
                  "Des aliments jusqu’aux produits d'entretien en passant par les emballages, tous nos fournisseurs et produits sont minutieusement sélectionnés afin de minimiser notre impact écologique."
                )}
              </small>
              <br />
              <br />
              <h4 className="uppercase bold">{t("L'humain")}</h4>
              <small>
                {t(
                  "Chez IKI, nous considérons et chérissons chaque membre de notre équipe et nos clients comme si nous faisions tous partie d’une même grande famille."
                )}
              </small>
              <br />
              <br />
              <h4 className="uppercase bold">{t("Bien être")}</h4>
              <small>
                {t(
                  "Le bien-être de chaque membre de notre équipe et de chacun de nos clients est une des priorités absolues d’IKI. Dans ce contexte, nous veillons particulièrement à que ce chacun se sente bien accueilli dans un cadre sain et chaleureux."
                )}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="order">
        <div className="order-title">
          <div>
            <h1 className="uppercase optional">{t("Une petite envie")}</h1>
            <h1 className="uppercase">{t("Passez commande !")}</h1>
          </div>
          <ArrowOrder />
        </div>
        <div className="order-buttons">
          <button
            data-tf-popup="VdEkYv9y"
            data-tf-iframe-props="title=Iki orders"
            data-tf-medium="snippet"
            className="button-secondary"
          >
            {t("A emporter")}
          </button>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ubereats.com/be-en/store/iki-%E2%80%93-cuisine-eurasienne/cP97CQ5uQkGV24xuJ_zc-w"
          >
            <button className="button-secondary">{t("Uber eats")}</button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://deliveroo.be/fr/menu/brussels/ixelles-nord/iki-salads"
          >
            <button className="button-secondary">{t("Deliveroo")}</button>
          </a>
        </div>
      </div>
      <div className="products">
        <div className="products-text">
          <div className="centeredBlock">
            <h2>{t("Des produits de qualité")}</h2>
            <h4>
              {t(
                "Nous mettons un point d’honneur à trier nos ingrédients sur le volet pour ne vous proposer que des produits de qualité et, lorsque c’est possible, locaux."
              )}
            </h4>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1y23WE-4b6Vrd7ZwgVbq66pFtGuYb7nj8/view?usp=sharing"
            >
              <button>{t("Voir notre menu")}</button>
            </a>
          </div>
        </div>
        <div className="products-image">
          <StaticImage
            className="products-backgroundImage"
            src="../images/bun-meal.webp"
            alt="bun"
          />
          <div />
          <div className="products-image-deco">
            <Onion />
            <div>
              <p className="uppercase">{t("Good food")}</p>
              <p className="uppercase">{t("makes")}</p>
              <p className="uppercase">{t("good people")}</p>
            </div>
          </div>
        </div>
      </div>
      <BannerFollow />
      <InstaFeeds token={process.env.REACT_APP_TOKEN_INSTAGRAM} limit={12} />
      <div className="findUs">
        <div className="findUs-text">
          <div className="ikiBackground">
            <IkiBackground />
          </div>
          <div className="findUs-textBlock">
            <h2>{t("Nous trouver")}</h2>
            <h3 className="uppercase">
              {t("2 place de londres, 1050 Ixelles")}
            </h3>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/IKI/@50.83767,4.3657749,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3c5e28af11f83:0x2b5e74f0a9f65c2c!8m2!3d50.83767!4d4.3679636"
            >
              <button>{t("Voir les horaires")}</button>
            </a>
          </div>
        </div>
        <div className="findUs-image">
          <StaticImage
            className="map"
            src="../images/map.webp"
            alt="map"
            placeholder="blurred"
            objectPosition="0 0"
          />
          <div className="pop-up">
            <div className="background-pop-up" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/IKI/@50.83767,4.3657749,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3c5e28af11f83:0x2b5e74f0a9f65c2c!8m2!3d50.83767!4d4.3679636"
            >
              <button>{t("Voir la carte")}</button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
