import React from "react"

import "./styles.css"

import { StarBanner } from "../svgs/starBanner"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const BannerMenu = ({ className }) => {
  const { t } = useTranslation("banner")
  return (
    <div className={`bannerMenu banner ${className ?? ""}`}>
      <div>
        <span>{t("Eurasien")}</span>
        <StarBanner />
        <span>{t("Buns")}</span>
        <StarBanner />
        <span>{t("Bowls")}</span>
        <StarBanner />
        <span>{t("Bites")}</span>
        <StarBanner />
      </div>
      <div>
        <span>{t("Eurasien")}</span>
        <StarBanner />
        <span>{t("Buns")}</span>
        <StarBanner />
        <span>{t("Bowls")}</span>
        <StarBanner />
        <span>{t("Bites")}</span>
        <StarBanner />
      </div>
    </div>
  )
}
