import React from "react"

export const ArrowOrder = () => (
  <svg
    width="226"
    height="179"
    viewBox="0 0 226 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.864 109.293C151.416 105.589 176.372 98.1421 196.893 83.9175C204.359 78.7423 210.443 72.6093 216.722 66.3688C217.163 65.9316 217.196 65.3285 216.795 65.0236C216.391 64.7203 215.705 64.8301 215.263 65.2708C209.104 71.3901 203.15 77.4159 195.827 82.4909C175.598 96.5138 150.978 103.797 127.768 107.422L127.758 107.112C127.659 103.745 124.534 100.669 119.845 98.3117C113.125 94.9317 103.262 92.9306 95.6419 93.1658C92.2178 93.2721 89.2261 93.8429 87.11 94.8823C85.4598 95.692 84.2913 96.7871 83.7043 98.1448C82.4553 101.038 84.1509 103.613 87.7696 105.578C95.3359 109.689 111.199 111.462 118.657 110.568C120.986 110.288 123.338 109.975 125.704 109.623C125.74 110.211 125.772 110.787 125.774 111.355C125.775 112.974 125.52 114.547 124.243 116.306C122.249 119.047 119.167 121.21 115.719 122.948C110.337 125.657 104.057 127.314 99.3121 128.337C72.8858 134.035 40.323 133.112 23.323 124.612C7.4393 115.612 8.99626 115.455 2.33092 108.508C1.98905 108.15 1.31387 108.184 0.822967 108.581C0.332069 108.977 0.212664 109.59 0.557108 109.946C7.26469 116.939 6.82301 117.112 21.7651 126.233C40.323 134.612 72.2187 136.064 99.3455 130.217C104.288 129.151 110.824 127.407 116.428 124.586C120.307 122.632 123.74 120.161 125.983 117.077C127.996 114.309 128.002 111.924 127.864 109.293ZM125.596 107.75L125.593 107.596C125.507 104.652 122.582 102.113 118.482 100.052C112.024 96.8043 102.549 94.8742 95.2232 95.1014C92.7118 95.1778 90.4656 95.5057 88.7071 96.1457C87.257 96.675 86.1635 97.4022 85.7098 98.4576C85.2763 99.4538 85.4237 100.366 85.9589 101.205C86.5849 102.19 87.7163 103.067 89.177 103.862C96.4482 107.812 111.693 109.51 118.86 108.65C121.086 108.382 123.332 108.084 125.596 107.75Z"
      fill="#E8BC00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.049 61.496C220.165 61.873 220.131 62.4574 220.086 63.0872C219.981 64.5871 219.602 66.3501 219.46 67.1799C219.37 67.7138 219.779 68.1105 220.369 68.0707C220.962 68.0293 221.514 67.5648 221.603 67.031C221.776 66.0146 222.277 63.6653 222.28 61.9723C222.281 61.0643 222.117 60.3268 221.802 59.9194C221.266 59.2244 220.221 59.2291 218.733 60.1861C216.973 61.3191 214.092 64.051 210.811 64.9298C210.216 65.0882 209.768 65.6343 209.814 66.1417C209.859 66.6526 210.379 66.9366 210.977 66.7766C214.105 65.9374 216.948 63.6575 218.922 62.2196C219.297 61.9467 219.768 61.6616 220.049 61.496Z"
      fill="#E8BC00"
    />
  </svg>
)
