import React from "react"

import "./styles.css"

import { StarBanner } from "../svgs/starBanner"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const BannerFollow = ({ className }) => {
  const { t } = useTranslation("banner")
  return (
    <div className={`bannerFollow banner ${className ?? ""}`}>
      <div>
        <span>{t("Suivez-nous")}</span>
        <StarBanner />
        <span>{t("Suivez-nous")}</span>
        <StarBanner />
      </div>
      <div>
        <span>{t("Suivez-nous")}</span>
        <StarBanner />
        <span>{t("Suivez-nous")}</span>
        <StarBanner />
      </div>
    </div>
  )
}
